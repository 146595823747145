import React, {
  Fragment,
  useState,
  useEffect
} from "react";

import {
  Button,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Grid,
  Toolbar
} from "@material-ui/core";
import RecordVoiceOverTwoToneIcon from '@material-ui/icons/RecordVoiceOverTwoTone';
import SubtitlesTwoToneIcon from '@material-ui/icons/SubtitlesTwoTone';
import ImageSearchIcon from '@material-ui/icons/ImageSearchTwoTone';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import TranslateTwoToneIcon from '@material-ui/icons/TranslateTwoTone';
import {makeStyles} from "@material-ui/core/styles";
import Logo from '../assets/logo.png'
import { useHistory } from "react-router-dom";

import serviceStatuses from "../data/disable-services";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 450,
    marginTop: '10px'
  },
  drawerPaper: {
    width: 435,
    marginTop: '0px'
  }
}));
export default function SideDrawer(props) {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <Box
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <Toolbar>
          <Button onClick={toggleDrawer(false)}>
            <MenuTwoToneIcon fontSize="large" align="center"/>
          </Button>
          <img className="navBarLogo" alt="Logo" onClick={ () => history.push("/")} src={Logo} height={"100%"}/>
          <p className="appBarSideDrawer">
            | Translate
          </p>
        </Toolbar>

        <Divider/>

        <ListItem
        button = {true}
        onClick={ () => history.push("/translate")}
        disabled={serviceStatuses.disableTranslate}
        >
          <ListItemText
            primary={<Grid container justify="left">
              <TranslateTwoToneIcon fontSize="large" align="center"/>
              <Typography variant="button">
                <Box sx={{ fontSize: 'large', fontWeight: 'bold', m: 1 }}>&nbsp; Translation</Box>
              </Typography>
            </Grid>}
            secondary={
              <React.Fragment>
                {"Text, Document, and Speech Audio Translation"}
              </React.Fragment>
              }
            >
          </ListItemText>
        </ListItem>

        <Divider/>

        <ListItem
        button = {true}
        onClick={ () => history.push("/speech")}
        disabled={serviceStatuses.disableSpeech}
        >
          <ListItemText
            primary={<Grid container justify="left">
              <RecordVoiceOverTwoToneIcon fontSize="large" align="center"/>
              <Typography variant="button">
                <Box sx={{ fontSize: 'large', fontWeight: 'bold', m: 1 }}>&nbsp; Speech</Box>
              </Typography>
            </Grid>}
            secondary={
              <React.Fragment>
                {"Speech Generation and Transcription"}
              </React.Fragment>
              }
            >
          </ListItemText>
        </ListItem>

        <Divider/>

        <ListItem
        button = {true}
        onClick={ () => history.push("/textanalysis")}
        disabled={serviceStatuses.disableTextAnalysis}
        >
          <ListItemText
            primary={<Grid container justify="left">
              <SubtitlesTwoToneIcon fontSize="large" align="center"/>
              <Typography variant="button">
                <Box sx={{ fontSize: 'large', fontWeight: 'bold', m: 1 }}>&nbsp; Text Analysis</Box>
              </Typography>
            </Grid>}
            secondary={
              <React.Fragment>
                {"Text Summarization and Sentiment Analysis"}
              </React.Fragment>
              }
            >
          </ListItemText>
        </ListItem> 

        <Divider/>

        {/* Removing Vision services for now 
        <ListItem
          button = {true}
          onClick={ () => history.push("/vision")}
          disabled={serviceStatuses.disableVision}
        >
          <ListItemText
            primary={
              <Grid container justify="left">
                <ImageSearchIcon fontSize="large" align="center"/>
                <Typography variant="button">
                  <Box sx={{ fontSize: 'large', fontWeight: 'bold', m: 1 }}>&nbsp; Vision</Box>
                </Typography>
              </Grid>
            }
            secondary={
              <React.Fragment>
                {"Text Extraction from Images"}
              </React.Fragment>
              }
          >
          </ListItemText>
        </ListItem>

        <Divider/> */}

      </List>
    </Box>
  );

  return (
    <div>
      <Fragment>
        {props.style == "icon" && (
          <Button onClick={toggleDrawer(true)}>
            <MenuTwoToneIcon fontSize="large"/>
          </Button>
        )}
        {props.style == "text" && (
          <Button color="primary" onClick={toggleDrawer(true)}>
            Menu
          </Button>
        )}
        <Drawer
          className={classes.drawer}
          classes={{paper: classes.drawerPaper}}
          anchor={"left"}
          open={state}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </Fragment>
    </div>
  );
}